import {
  AbsoluteCenter,
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  HStack,
  Icon,
  Input,
  Skeleton,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ReportObject } from '@karya/core';
import { createColumnHelper, PaginationState } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { HiArrowPath, HiOutlineArrowDownTray } from 'react-icons/hi2';
import { useLocation, useParams } from 'react-router-dom';
import { AdvancedTable } from 'src/components/AdvancedTable';
import { FilePreview } from 'src/components/Reports/FilePreview';
import { ReportKind, reportsApi, RequestSource } from 'src/features/reportsApi';
import { ButtonVariant } from 'src/themes/ButtonVariants';
import GranularSearch, { Filter } from '../Utils/GranularSearch';
import { ReportComponenetPreview, ReportComponenetPreviewMeta } from './ComponentPreview';
import { useRef } from 'react';

type PageParams = {
  kind: ReportKind;
  id: string;
};

type Props = Partial<PageParams> & {
  src: RequestSource; // Src to pass in the headers
};

const { useGetReportByKindAndIdQuery, useGetReportResultQuery, useGetReportDownloadUrlQuery } = reportsApi;

const columnHelper = createColumnHelper<any>();

export const Report = ({ kind: reportKind, id: reportId, src }: Props) => {
  // This ref will store if the first update has occurred
  const reportParamsFirstChange = useRef(true);
  const pageParams = useParams<PageParams>();
  const kind = reportKind || pageParams.kind;
  const id = reportId || pageParams.id;

  if (!kind || !id) {
    return <div>Invalid report</div>;
  }

  const {
    data: reportData,
    isError,
    error,
  } = useGetReportByKindAndIdQuery({
    kind,
    id,
    src,
  });
  const [reportParams, setReportParams] = useState<ReportObject['params']>([]);
  const [paramList, setParamList] = useState<string[]>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [mediaEnabled, setMediaEnabled] = useState(false);
  const [searchQuery, setSearchQuery] = useState<Record<string, string>>({});
  const [filters, setFilters] = useState<Filter[]>([]);
  const [forceRefresh, setForceRefresh] = useState(false);

  // See if we already have search params in the URL
  const location = useLocation();

  const { reportResult, isSuccess, isFetching } = useGetReportResultQuery(
    {
      kind,
      id,
      limit: pagination.pageSize,
      page: pagination.pageIndex,
      params: paramList,
      searchQuery: Object.keys(searchQuery).length > 0 ? searchQuery : undefined,
      forceRefresh,
      src,
    },
    {
      // Skip the query if the report is dynamic and no params are provided
      skip: !reportData || (reportData.type === 'dynamic' && paramList.length === 0),
      selectFromResult: ({ data, isSuccess, isFetching }) => {
        return {
          reportResult: data,
          isSuccess,
          isFetching,
        };
      },
    },
  );

  const tableColumns = useMemo(() => {
    if (reportData && reportResult?.meta.columns) {
      return reportResult.meta.columns;
    } else {
      return [];
    }
  }, [reportData, reportResult?.meta.columns]);

  const categories = useMemo(() => {
    return tableColumns.map((column) => {
      return {
        id: column,
        displayString: column.replace(/_/g, ' '),
      };
    });
  }, [tableColumns]);

  const setGranularFilters = (filters: Filter[]) => {
    const searchQuery: Record<string, string> = {};
    tableColumns.forEach((column) => {
      searchQuery[column] = '';
    });
    filters.forEach((filter) => {
      searchQuery[filter.category.id] = filter.input;
    });
    setSearchQuery(searchQuery);
  };

  const { downloadUrl, isUrlError, isUrlFetching } = useGetReportDownloadUrlQuery(
    {
      kind,
      id,
      params: paramList,
      mediaEnabled,
      searchQuery: Object.keys(searchQuery).length > 0 ? searchQuery : undefined,
      forceRefresh,
      src,
    },
    {
      // Skip the query if the report is dynamic and no params are provided
      skip: !reportData || (reportData.type === 'dynamic' && paramList.length === 0),
      selectFromResult: ({ data, isFetching, isError }) => {
        return {
          downloadUrl: data ? data.url : '',
          isUrlFetching: isFetching,
          isUrlError: isError,
        };
      },
    },
  );

  // Reset the reports state when the report changes
  useEffect(() => {
    setMediaEnabled(false);
    setParamList([]);
    setMediaEnabled(false);
    setFilters([]);
    setGranularFilters([]);
    setForceRefresh(false);
  }, [kind, id]);

  // Set the report params when the report data is fetched
  useEffect(() => {
    if (!reportData) {
      return;
    }
    let params: ReportObject['params'] = reportData.params?.map((param: any) => {
      return { ...param, value: param.value ?? '' };
    });
    setReportParams(params ?? []);
  }, [reportData]);

  // Extract all query parameters as key-value pairs while filtering out the 'paramlist' keys
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (reportParams && reportParams.length > 0) {
      const newParams = [...reportParams];
      reportParams.forEach((param, index) => {
        if (queryParams.has(param.name)) {
          newParams[index].value = queryParams.get(param.name) ?? '';
        }
      });
      // Check if this is the first change of reportParams
      if (reportParamsFirstChange.current) {
        setReportParams(newParams);
        reportParamsFirstChange.current = false;
        // Set to false after the first change
      }
    }
  }, [reportParams, location.search]);

  if (isError) {
    // Display error message
    return (
      <AbsoluteCenter w="100%">
        <VStack>
          <Heading>
            {/*  @ts-ignore */}
            {error.status}: {error.data.title}
          </Heading>
          <Text fontSize="xl" fontWeight="medium">
            {/*  @ts-ignore */}
            {error.data.messages[0]}
          </Text>
        </VStack>
      </AbsoluteCenter>
    );
  }

  return (
    <Box pb={16}>
      <Heading fontSize={{ base: 'xl', md: '2xl' }} mx={{ base: 6, md: 12 }} mt={{ base: 6, md: 12 }} mb={4}>
        {reportData?.name}
      </Heading>
      {reportParams && reportParams.length > 0 && (
        <Box bg="gray.100" px={{ base: 6, md: 12 }} mb={8} mt={4} py={8}>
          <Heading fontSize={{ base: 'md', md: 'lg' }} mb={{ base: 4, md: 8 }}>
            Parameters
          </Heading>
          {reportParams.map((param, index: number) => (
            <Stack mt={2} key={param.name} width="100%" spacing={1}>
              <Text fontSize="sm" fontWeight="bold">
                {param.name}
              </Text>
              <Flex alignItems="center" gap={2}>
                <Input
                  flexShrink={0}
                  size="sm"
                  width={{ base: '100%', md: '40%' }}
                  placeholder={param.name}
                  background="white"
                  value={param.value}
                  onChange={(e) => {
                    const newParams = [...reportParams];
                    newParams[index].value = e.target.value;
                    setReportParams(newParams);
                  }}
                />
                <Text fontSize="sm" color="gray.700">
                  {param.comments}
                </Text>
              </Flex>
            </Stack>
          ))}
          <Button
            colorScheme="green"
            size={{ base: 'sm', md: 'md' }}
            mt={6}
            mb={8}
            onClick={() => {
              setParamList(reportParams.map((param) => param.value));
            }}
          >
            Generate Report
          </Button>
        </Box>
      )}

      <Box mx={{ base: 6, md: 12 }}>
        <VStack align="start" width="100%">
          <Stack width="50%">
            <Button
              size="sm"
              alignSelf="start"
              leftIcon={<Icon as={HiArrowPath} />}
              variant={ButtonVariant.submit}
              isDisabled={isFetching || !isSuccess}
              onClick={() => {
                setForceRefresh(true);
              }}
            >
              Refresh Data
            </Button>
            <GranularSearch
              categories={categories}
              setAppliedFilters={setGranularFilters}
              filters={filters}
              setFilters={setFilters}
            />
          </Stack>
          {isFetching ? (
            <Stack width="100%">
              <Skeleton height="20px" width="100%" />
              <Skeleton height="20px" width="100%" />
              <Skeleton height="20px" width="100%" />
            </Stack>
          ) : isSuccess && reportResult ? (
            reportResult.data.length > 0 && reportResult.meta.kind === kind && reportResult.meta.id === id ? (
              <>
                <AdvancedTable
                  data={reportResult.data}
                  columns={tableColumns.map((column: string) =>
                    columnHelper.accessor(column, {
                      header: `${column.replace(/_/g, ' ')}`,
                      cell: ({ row }) => {
                        const value: any = row.getValue(column);
                        if (typeof value === 'string' && value.startsWith('https://')) {
                          return <FilePreview url={value} />;
                        }
                        if (value && typeof value === 'object' && 'type' in value && value.type === '__component__') {
                          const previewMeta = value as ReportComponenetPreviewMeta;
                          return <ReportComponenetPreview {...previewMeta} />;
                        } else {
                          return value;
                        }
                      },
                    }),
                  )}
                  pageSize={20}
                  pageCount={Math.ceil(reportResult.pagination.totalCount / 20)}
                  paginationState={pagination}
                  onPaginationChange={setPagination}
                  manualPagination
                />
                <HStack mt={8}>
                  <DownloadCSV url={downloadUrl} isLoading={isUrlFetching} isError={isUrlError} />
                  <Checkbox
                    isChecked={mediaEnabled}
                    onChange={(e) => {
                      setMediaEnabled(e.target.checked);
                    }}
                  >
                    Download with media
                  </Checkbox>
                </HStack>
              </>
            ) : (
              <Text>Nothing to show.</Text>
            )
          ) : null}
        </VStack>
      </Box>
    </Box>
  );
};

const DownloadCSV = ({ url, isLoading, isError }: { url: string; isLoading: boolean; isError?: any }) => {
  return (
    <Button
      as="a"
      href={url}
      isDisabled={isError}
      size={{ base: 'sm', md: 'md' }}
      leftIcon={<Icon as={HiOutlineArrowDownTray} />}
      isLoading={isLoading}
    >
      Download CSV
    </Button>
  );
};
